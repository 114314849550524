import { Pagination } from 'antd';

export default function AppPagination({ defaultCurrent, total, onPageChange, defaultPageSize }) {
  const handleChange = (pageNumber) => {
    onPageChange(pageNumber);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        padding: '1rem 0',
      }}
    >
      <Pagination
        defaultPageSize={defaultPageSize}
        defaultCurrent={defaultCurrent}
        total={total}
        onChange={handleChange}
      />
      <br />
    </div>
  );
}
