import { Tabs } from 'antd';
import PageContent from '../../common/PageContent';
import TestEmbbedTelerik from './TestEmbbedTelerik';
//import './style.css';

export default function Analytics() {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    // {
    //   key: '1',
    //   label: 'Test Report',
    //   children: <TestReportAnalytics/>,
    // },
    // {
    //   key: '2',
    //   label: 'Result Comparison',
    //   children: <ResultComparison />,
    //   //   children: <FacilityComparativeAnalytics />,
    // },
    // {
    //   key: '3',
    //   label: 'Infragistics Test',
    //   children: <InfragisticsTest />,
    //   //   children: <FacilityComparativeAnalytics />,
    // },
    {
      key: '1',
      label: 'Facility Analytics',
      children: <TestEmbbedTelerik />,
      //   children: <FacilityComparativeAnalytics />,
    },
    
    // {
    //   key: '3',
    //   label: 'Tab 3',
    //   children: <>Content of Tab Pane 3</>,
    // },
  ];
  return (
    <PageContent>
      <div style={{ padding: '1rem 2rem' }}>
        <br />
        <Tabs tabPosition="left" defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </PageContent>
  );
}
