import {
  Tabs,
  Breadcrumb,
  Row,
  Col,
  Form,
  Select,
  Input,
  Space,
  Spin,
  Switch,
  Empty,
  List,
  Button as AntButton,
  Tag,
  Divider,
  Upload,
} from 'antd';
import { Link } from 'react-router-dom';
import './style.css';
import Button from '../../../../components/common/Button';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import {
  sendMailCreateTemplate,
  getFacilitiyLight,
  getFacilitiyV2,
  getEmailTemplates,
  getToken,
} from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';
import axios from 'axios';

export default function TemplateScreen(props) {
  const [form] = Form.useForm();
  const [sending, setSending] = useState(false);
  const [facilityData, setFacilityData] = useState([]);
  const [facilityDropdown, setFacilityDropdown] = useState([]);
  const [loadingFacility, setLoadingFacility] = useState(true);
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 100;
  const [selectedFacilityList, setSelectedFacilityList] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [refetchTemplates, setRefetchTemplates] = useState(false);
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [fileList, setFileList] = useState([]);

  const filteredFacilities = facilityData?.filter((facility) =>
    facility.facilityName.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchTemplateData();
  }, []);

  useEffect(() => {
    console.log('refetch', refetchTemplates);
    if (refetchTemplates) {
      fetchTemplateData();
      setRefetchTemplates(false);
    }
  }, [refetchTemplates]);

  const fetchData = () => {
    setLoadingFacility(true);
    getFacilitiyLight()
      .then((response) => {
        setLoadingFacility(false);
        const facilityData = response?.data;
        const selectables = facilityData?.map((x) => {
          return {
            value: x.facilityId,
            label: x.facilityName,
          };
        });
        setFacilityData(facilityData);
        //setFacilityDropdown(selectables);
      })
      .catch((error) => {
        setLoadingFacility(false);
      });
  };

  const fetchTemplateData = () => {
    getEmailTemplates()
      .then((response) => {
        const templates = response.result.data;
        const m = templates?.map((item) => ({
          label: item.templateName,
          value: item.templateId,
        }));
        setTemplateList(m);
        setTemplateData(templates);
      })
      .catch((error) => {});
  };

  const handleRenderFacilityDisplay = () => {
    if (loadingFacility) {
      return (
        <div style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    } else if (!loadingFacility && !facilityData.length) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Empty />
          </div>
        </div>
      );
    } else if (!loadingFacility && facilityData.length) {
      return renderFacilityDisplay();
    }
  };

  const renderFacilityDisplay = () => {
    return (
      <div
        style={{
          height: '70vh',
          overflowY: 'scroll', // Use 'auto' instead of 'hidden'
          backgroundColor: '#FFFFFF',
          //border: '1px solid gray',
        }}
      >
        <div
          style={{
            padding: '1rem',
          }}
        >
          <h4 style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Facilities</h4>
          <Divider />
          <List
            itemLayout="horizontal"
            dataSource={filteredFacilities}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => {
                  handleAddToList(item);
                }}
                style={{ padding: '1rem', cursor: 'pointer' }}
              >
                <List.Item.Meta title={item.facilityName} />
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  };

  const renderSelectedFacilities = () => {
    return (
      <div style={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {selectedFacilityList?.map((x) => (
          <div style={{ paddingBottom: '0.2rem' }}>
            <Tag
              bordered={false}
              closable
              style={{ padding: '0.5rem' }}
              onClose={() => handleRemoveFromList(x)}
            >
              {x.facilityName}
            </Tag>
          </div>
        ))}
      </div>
    );
  };

  const handleAddToList = (facility) => {
    setSelectedFacilityList((prev) => [facility, ...prev]);
  };

  const handleRemoveFromList = (facility) => {
    const filter = selectedFacilityList.filter((x) => x !== facility);
    setSelectedFacilityList(filter);
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const onFinish = async (values) => {
    //setSending(true);

    const facilityIds = selectedFacilityList?.map((x) => {
      return x.facilityId;
    });

    const formData = new FormData();

    formData.append('subject', values.subject);
    formData.append('messageBody', values.messageBody);
    formData.append('recipients', values.recipients);
    formData.append('facilityIds', facilityIds);
    formData.append('processingStatus', '');
    formData.append('templateName', values.subject);
    formData.append('templateDescription', values.message);
    formData.append('saveTemplate', values.saveTemplate);

    fileList.forEach((file) => {
      formData.append('attachments', file.originFileObj); // Add files with the same key
    });

    console.log(formData.append('saveTemplate', values.saveTemplate));

    try {
      const response = await axios.post(`${baseURL}/EmailNotificationTemplate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      console.log(response);

      if (response.data?.result?.status?.code === '00') {
        setLoading(false);
        setTimeout(() => {
          toggleApprovalForm(false);
        }, 1000);
        return openNotification({
          type: 'success',
          title: 'Success',
          message: 'Confirmed successfully',
        });
      } else {
        openNotification({
          type: 'error',
          title: 'Error',
          message: 'Something went wrong with the request',
        });
      }
    } catch (error) {
      console.error('Error during submission', error);
      openNotification({
        type: 'error',
        title: 'Error',
        message: 'An error occurred while submitting',
      });
    } finally {
      setSending(false);
    }

    //   const payload = {
    //     subject: values.subject,
    //     messageBody: values.message,
    //     recipients: values.recipients,
    //     facilityIds: facilityIds,
    //     processingStatus: '',
    //     templateName: values.subject,
    //     templateDescription: values.message,
    //     saveTemplate: saveTemplate,
    //   };

    //   sendMailCreateTemplate(payload)
    //     .then((response) => {
    //       setSending(false);
    //       const responseStatus = response?.result?.status;
    //       if (responseStatus?.code === '00') {
    //         form.setFieldsValue({
    //           subject: '',
    //           message: '',
    //           recipients: '',
    //         });
    //         setSelectedFacilityList([]);
    //         setRefetchTemplates(true);

    //         return openNotification({
    //           type: 'success',
    //           title: 'Success',
    //           message: 'Action was successfull',
    //         });
    //       } else {
    //         return openNotification({
    //           type: 'error',
    //           title: 'Failed',
    //           message: 'Action failed. Please try again',
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       setSending(false);
    //       return openNotification({
    //         type: 'error',
    //         title: 'Failed',
    //         message: 'Something went wrong. Please contact your system admin',
    //       });
    //     });
  };

  // const onFill = () => {
  //   form.setFieldsValue({
  //     note: 'Hello world!',
  //     gender: 'male',
  //   });
  // };

  // const onReset = () => {
  //   form.resetFields();
  // };

  const handleSelectTemplate = (templateId) => {
    const selectedTemplate = templateData?.filter((x) => x.templateId === templateId)[0];
    form.setFieldsValue({
      subject: selectedTemplate?.templateSubject,
      message: selectedTemplate?.templateContent,
    });
  };

  return (
    <div className="config__container">
      <div className="config__content pl-2rem pr-2rem mt-20">
        <div className="breadcrumb__container" style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flexGrow: 1 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/settings">Settings</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Templates</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Input
              value={search}
              placeholder="Search Facility"
              onChange={(e) => setSearch(e.target.value)}
              style={{ width: '400px' }}
            />
          </div>
        </div>
        <Row gutter={20}>
          <Col lg={{ span: 16 }} md={{ span: 16 }} sm={{ span: 24 }} sx={{ span: 24 }}>
            <div>
              <Form
                initialValues={{
                  subject: '',
                }}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                //onFill={onFill}
                //onReset={onReset}
                layout="vertical"
                // style={{
                //   maxWidth: 600,
                // }}
              >
                <Row gutter={2}>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item name="templateId" label="Template">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select Template"
                        options={templateList}
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        onChange={handleSelectTemplate}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    {renderSelectedFacilities()}
                    <br />
                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="subject"
                      label="Subject"
                      rules={[
                        {
                          required: true,
                          message: 'Subject is required',
                        },
                      ]}
                    >
                      <Input placeholder="Subject" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="recipients"
                      label="Recipients - seperate multiple recipients with a comma (,)"
                    >
                      <Input placeholder="Enter email address" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item valuePropName="fileList">
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleFileChange}
                        beforeUpload={() => false} // Prevent default upload behavior
                        multiple
                      >
                        {fileList.length < 1 && (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Attachment</div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="message"
                      label="Message"
                      rules={[
                        {
                          required: true,
                          message: 'Message is required',
                        },
                      ]}
                    >
                      <Input.TextArea rows={8} />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Space style={{ flexGrow: 1 }}>
                        <Button
                          type="primary"
                          appearance="default"
                          htmlType="submit"
                          disabled={sending}
                        >
                          Send{' '}
                          {sending && <Spin indicator={<LoadingOutlined spin />} size="small" />}
                        </Button>
                      </Space>
                      <Space>
                        <Switch
                          defaultChecked={saveTemplate}
                          onChange={(e) => setSaveTemplate(e)}
                        />
                        <Space>Save Template</Space>
                      </Space>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <div>{handleRenderFacilityDisplay()}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
