import React, { useEffect, useState } from 'react';
import { Alert, Popconfirm, Tree, Table, Empty, Tag } from 'antd';
import { CarryOutOutlined, MoreOutlined } from '@ant-design/icons';
import compareDesc from 'date-fns/compareDesc';
import moment from 'moment';
import { properCase, formatDate, datelize } from '../../helpers/utils';
//import Table from '../../components/common/Table';
import Menu from '../../components/common/Menu';
import Dropdown from '../../components/common/Dropdown';
// import Popover from 'components/common/Popover';
import { actionInProgress, openNotification } from '../../helpers/notification';
import './index.css';
import '../../scrollfix.css';
import { LockFilled } from '@ant-design/icons';
import { getReports, getReportsV2, toggleQaqcReportVisibilityApi } from '../../helpers/api';
import LoadingContent from '../common/LoadingContent';
import AppPagination from '../common/AppPagination';
import LoaderSkeleton from '../common/LoaderSkeleton';

let overflowMenu, moreOutlinedIcon;

const handlePeriodFilter = (entryDate, periodX) => {
  return (
    moment(entryDate).isSameOrAfter(moment(periodX[0], 'day')) &&
    moment(entryDate).isSameOrBefore(moment(periodX[1], 'day'))
  );
};

let facilityX, consultantX, quarterX, reportTypeX, statusX, sectorX, stateX, eiaStatusX, periodX;

const ReportTable = ({
  userCategory,
  userPerm,
  setReport,
  setModal,
  facilityFilter,
  consultantFilter,
  statusFilter,
  reportTypeFilter,
  quarterFilter,
  stateFilter,
  sectorFilter,
  eiaFilter,
  periodFilter,
  searchValue,
}) => {
  const [row, setRow] = useState('');
  consultantX = consultantFilter;
  statusX = statusFilter;
  reportTypeX = reportTypeFilter;
  quarterX = quarterFilter;
  sectorX = sectorFilter;
  stateX = stateFilter;
  facilityX = facilityFilter;
  eiaStatusX = eiaFilter;
  periodX = periodFilter;

  const userCat = userCategory.trim().toLowerCase();
  const isConsultant = userCat === 'consultant';
  // const canViewReport = userCat === 'edm' || userCat === 'regulator' || userCat === 'consultant';
  // const canViewComparativeReport = userCat === 'regulator' || userCat === 'edm';
  const canViewReport = userPerm.includes('view_report');
  const canViewComparativeReport = userPerm.includes('view_comparative_report');
  const canLockUnlockReport = userPerm.includes('lock_report');

  const [loadingAction, setLoadingAction] = useState();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    fethReportData();
  }, [searchValue, pageNum]);

  const fethReportData = () => {
    //console.log("dsfsdfsdfs")
    setLoading(true);
    getReportsV2(searchValue, pageNum, pageSize)
      .then((response) => {
        setLoading(false);

        const totalCount = response.result.totalCount;
        const reportData = response.result.data;
        setTotal(totalCount);
        setData(reportData);
      })
      .catch((err) => {
        //setPage('error');
      })
      .finally(() => setLoading(false));
  };

  const handleRenderDisplay = () => {
    if (loading) {
      return (
        <div style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    } else if (!loading && data.length < 1) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //border:"1px solid gray",
            height: '60vh',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Empty />
          </div>
        </div>
      );
    } else if (!loading && data.length) {
      return renderDisplay();
    }
  };

  const renderDisplay = () => {
    const columns = [
      {
        title: 'Title',
        dataIndex: 'reportTitle',
        key: 'reportTitle',
        ellipsis: true,
        render: (reportTitle, record) => {
          const spChecked = record.checkedSamplePointCount;
          return (
            <div>
              <div>{reportTitle}</div>
            </div>
          );
        },
      },
      {
        title: 'Created Date',
        dataIndex: 'entryDate',
        key: 'entryDate',
        render: (entryDate) => {
          const date = new Date(entryDate).toLocaleDateString('en-NG', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });

          return <span>{date}</span>;
        },
        ellipsis: true,
      },
      {
        title: 'Facility',
        dataIndex: 'facility',
        key: 'facility',
        ellipsis: true,
        render: (facility, record) => {
          const spChecked = record.checkedSamplePointCount;
          return (
            <div>
              <div>{facility}</div>
              <div style={{ fontSize: '0.8em', color: 'gray' }}>{`${spChecked} ${
                spChecked > 1 ? 'Sample Points Checked' : 'Sample Point Checked'
              }`}</div>
            </div>
          );
        },
      },
      {
        title: 'State',
        ellipsis: true,
        dataIndex: 'stateName',
        key: 'stateName',
        render: (stateName) => <div>{properCase(stateName)}</div>,
      },
      {
        title: 'Quarter',
        dataIndex: 'facility',
        render: (_, records) => {
          if (records.type && records.type.toLowerCase() === 'adhoc') {
            let entryDate = records.entryDate;
            let dueDate = records.dueDate;
            return (
              <span>
                {entryDate && dueDate
                  ? `${formatDate(new Date(entryDate), 'LLL do')} - ${formatDate(
                      new Date(dueDate),
                      'LLL do'
                    )}`
                  : '00:00'}
              </span>
            );
          }
          return (
            <span>
              {records.reportQuarter && records.reportYear
                ? `Q${records.reportQuarter}, ${records.reportYear}`
                : 'Not Available'}
            </span>
          );
        },
        onFilter: (entryDate, record) =>
          periodX && periodX[0] ? handlePeriodFilter(record.entryDate, periodX) : record,
        filterDropdownVisible: true,
        filteredValue: [periodX],
        key: 'entryDate',
      },
      {
        title: 'Type',
        dataIndex: 'reportType',
        key: 'reportType',
        render: (_, record) => {
          let background = '';

          if (record.reportType === 'NORMAL') background = '#0CB230';
          if (record.reportType === 'QAQC') background = '#FAAB10';
          // if (record.reportType === 'NORMAL') background = '#7CBDFF';
          // if (record.reportType === 'QAQC') background = '#FA1010';
          return (
            <Tag
              color={background}
              style={{
                width: '70px',
                textAlign: 'center',
                borderRadius: '100px',
              }}
            >
              {properCase(record.reportType)}
            </Tag>
          );
          // <span
          //   className={
          //     String(rType)
          //       .trim()
          //       .toLowerCase() === 'qaqc'
          //       ? 'qaqc'
          //       : 'not-qaqc'
          //   }
          // >
          //   {rType ? (rType === 'QAQC' ? 'QAQC' : properCase(String(rType).trim())) : 'NA'}
          // </span>
        },
      },
      {
        title: 'Status',
        dataIndex: 'reportStatus',
        key: 'reportStatus',
        render: (status, record) => {
          if (status === 'DRAFT')
            return (
              <Alert
                message={
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>{properCase(status)}</div>
                    <div>
                      {!record.isVisible && record.reportType === 'QAQC' && (
                        <LockFilled style={{ marginRight: '5px', color: '#2e5bff' }} />
                      )}
                    </div>
                  </div>
                }
                type="warning"
                style={{ fontSize: '0.8em', textAlign: 'center', padding: '0.3rem', width: '70px' }}
              />
            );
          if (status === 'PUBLISHED')
            return (
              <Alert
                message={
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>{properCase(status)}</div>
                    <div>
                      {!record.isVisible && record.reportType === 'QAQC' && (
                        <LockFilled style={{ marginRight: '5px', color: '#2e5bff' }} />
                      )}
                    </div>
                  </div>
                }
                type="success"
                style={{ fontSize: '0.8em', textAlign: 'center', padding: '0.3rem', width: '70px' }}
              />
            );
        },
      },
      {
        title: '',
        key: '',
        render: (_, record) => {
          return (
            <Dropdown overlay={overflowMenu} trigger={['click']}>
              <a
                className="ant-dropdown-link"
                href="/#"
                onClick={(e) => e.preventDefault() && actionInProgress()}
              >
                {moreOutlinedIcon(record)}
              </a>
            </Dropdown>
          );
          // if (!record.isVisible && record.reportType === 'QAQC' && isConsultant) {
          //   return <LockFilled style={{ }} />;
          // } else {
          //   return (
          //     <Dropdown overlay={overflowMenu} trigger={['click']}>
          //       <a
          //         className="ant-dropdown-link"
          //         href="/#"
          //         onClick={(e) => e.preventDefault() && actionInProgress()}
          //       >
          //         {moreOutlinedIcon(record)}
          //       </a>
          //     </Dropdown>
          //   );
          // }
        },
      },
    ];

    return <Table columns={columns} dataSource={data} size="middle" pagination={false} />;
  };

  const handleLockUnlockReport = () => {
    setLoadingAction(true);
    const payload = [row.reportId];
    toggleQaqcReportVisibilityApi(payload)
      .then((response) => {
        setLoadingAction(false);
        return openNotification({
          type: 'success',
          title: 'Success',
          //message: `${errorMessage}`,
        });
      })
      .catch((error) => {
        setLoadingAction(false);
        const errorMessage = error.data?.result?.status?.desc;
        return openNotification({
          type: 'error',
          title: 'Something went wrong',
          message: `${errorMessage}`,
        });
      });
  };

  moreOutlinedIcon = (record) => (
    <MoreOutlined
      className="icon-more-outline"
      onClick={() => setRow(record)}
      // onMouseEnter={() => setRow(record)}
    />
  );

  overflowMenu = (
    <Menu className="facility-overflow__menu" style={{ width: '150px' }}>
      {canViewReport && (
        <Menu.Item
          onClick={() => {
            setReport({ type: 'viewing', data: row });
            setModal('viewing');
          }}
        >
          <span>View Report</span>
        </Menu.Item>
      )}

      {canViewComparativeReport && !isConsultant && (
        <Menu.Item
          onClick={() => {
            setReport({ type: 'comparing', data: row });
            setModal('compare');
          }}
        >
          <span>Compare Result</span>
        </Menu.Item>
      )}

      {canLockUnlockReport && (
        <Popconfirm
          title={
            <>
              {/* <h3>Submitting is final </h3> */}
              <p>
                {row.isVisible
                  ? 'Are you sure you want to lock report? '
                  : 'Are you sure you want to unlock the report?'}
              </p>
            </>
          }
          onConfirm={handleLockUnlockReport}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item
            onClick={() => {
              // setReport({ type: 'comparing', data: row });
              // setModal('compare');
            }}
          >
            <span>{row.isVisible ? 'Lock Report' : 'Unlock Report'}</span>
          </Menu.Item>
        </Popconfirm>
      )}
    </Menu>
  );

  // const keyedData = data;
  // keyedData.forEach((dt) => (dt['key'] = dt.facilityId));

  // if (keyedData && keyedData.length > 0) {
  //   filterByFacility = filter(keyedData, 'facility');
  // }

  // let xColumns = [...columns];
  // if (isConsultant) xColumns = columns.filter((cl) => cl.title !== 'Consultant');

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <div>{handleRenderDisplay()}</div>
      <br />

      <AppPagination
        defaultCurrent={1}
        defaultPageSize={pageSize}
        total={total}
        onPageChange={(pageNumber) => {
          setPageNum(pageNumber);
        }}
      />
    </div>
  );
};

export default ReportTable;
